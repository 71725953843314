<template>
  <div class="clearBox">
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
    <ayl-main title="车辆信息" :isCollapse="false">
      <div slot="main" class="p24px">
    <div class="contents">
      <span>车牌号码:</span>
      <span> {{ data.plateNumber ||'- -' }}</span>
    </div>
    <div class="contents">
      <span>车辆归属:</span>
      <span> {{ data.carBelong ||'- -' }}</span>
    </div>
    <div class="contents">
      <span>车辆类型:</span>
      <span> {{ data.carType ||'- -' }}</span>
    </div>
    <div class="contents">
      <span>车辆状态:</span>
      <span> {{ data.carStatus ||'- -' }}</span>
    </div>
    <div class="contents">
      <span>设备号:</span>
      <span> {{ data.deviceName ||'- -' }}</span>
    </div>
    <div class="contents">
      <span>所属车队:</span>
      <span> {{ data.teamName ||'- -' }}</span>
    </div>
    <div class="contents">
      <span>品牌型号:</span>
      <span> {{ data.carBrand ||'- -' }}</span>
    </div>
    <div class="contents">
      <span>车辆自重:</span>
      <span> {{ data.carSelfWeight ||'- -' }}</span>
    </div>
    <div class="contents">
      <span>车辆载重:</span>
      <span> {{ data.calibrationTon ||'- -' }}</span>
    </div>
    </div>
    </ayl-main>
    <ayl-main title="加油信息" :isCollapse="false">
      <div slot="main" class="p24px">
        <div class="contents">
          <span>加油开始时间:</span>
          <span> {{ data.startTime|str2ymd}}</span>
        </div>
        <div class="contents">
          <span>加油结束时间:</span>
          <span> {{ data.endTime|str2ymd}}</span>
        </div>
        <div class="contents">
          <span>加油开始时间油量:</span>
          <span> {{ data.startOilMass ||'- -' }}</span>
        </div>
        <div class="contents">
          <span>加油结束时间油量:</span>
          <span> {{ data.endOilMass ||'- -' }}</span>
        </div>
        <div class="contents">
          <span>加油量:</span>
          <span> {{ data.differenceOilMass ||'- -' }}</span>
        </div>
        <div class="contents">
          <span>加油位置:</span>
          <span> {{ data.location ||'- -' }}</span>
        </div>
      </div>
    </ayl-main>
    <div class="tac" style="margin-top: 40px">
      <el-button style="margin-left: 30px" @click="$router.push('/oil-add/oil-add')">返回</el-button>
    </div>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        nav: [
          {name: "油耗管理", path: ""},
          {name: "加油情况", path: "/oil-add/oil-add"},
          {name: "查看详情", path: ""},
        ],
		data:{},
        form: {
          name: null,
          textarea: null
        },
        rules: {
        },
        submitLoading: false
      }
    },
    methods:{
      stropList(e){
        let str = ''
        str = ( e || []).join('、')
        return str
      }
    },
    async mounted () {
      this.data = await this.$api.getOilEventDetailInfo({
        eventType: '加油',
        plateNumber: this.$route.query.plateNumber,
        startTime: this.$route.query.startTime,
      })
    }
  }
</script>
<style lang='sass' scoped>
</style>
